import * as React from "react";
import { Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { Footer } from "../components/footer";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// import Header from "../components/header";
// markup
const About = ({ data }) => {
  const members = data?.allSanityTeamMember?.nodes;
  return (
    <div>
      

      <Helmet>
          <title>0039 STUDIO - About</title>
        </Helmet>

        <div id="header" name="header" class="min-h-screen  bg-white-100 flex lg:flex-row flex-col lg:justify-center ">
        <div class="lg:min-h-screen lg:w-5/12 lg:min-w-0 min-w-full bg-white 2xl:pl-40 xl:pl-40 lg:pl-20 lg:pt-20 flex flex-col z-10">
        <div class="lg:pl-0 lg:absolute lg:top-20 lg:pt-0 pt-5 pb-5 lg:pb-10 flex justify-center">
        <AnchorLink to="/#menu" stripHash>
              <StaticImage
                src="../images/logo.png"
                height="100"
                placeholder="tracedSVG"
                quality="100"
                alt="head-logo"
              ></StaticImage>
              </AnchorLink>
          </div>
          <div class="text-black opacity-30 mt-auto pb-20 lg:block hidden">
            <ul class="uppercase font-bold top-menu 2xl:text-title-main xl:text-title-middle lg:text-title-normal text-6xl ">
              <Link to="/work">
                <li class="transition transform hover:translate-x-6 cursor-pointer">
                  WORK
                </li>
              </Link>
              <Link to="/studio">
                {" "}
                <li class="transition transform hover:translate-x-6 cursor-pointer">
                  STUDIO
                </li>
              </Link>
            </ul>
          </div>
        </div>
        <div class="min-h-screen lg:w-7/12 lg:min-w-0 min-w-full bg-black lg:flex lg:bg-opacity-30 2xl:pl-40 xl:pl-40 lg:pl-20 bg-about flex flex-row lg:justify-start justify-center">
          <div class="mt-auto pb-20 lg:flex hidden">
          <div class="font-bold top-menu 2xl:text-title-main xl:text-title-middle lg:text-title-normal text-6xl   flex-column">
              {/* <div>Your brand tells<br></br> us a story, we<br></br> develop it for<br></br> the people.</div> */}
            </div>
          </div>

          <div class="top-menu 2xl:text-title-main xl:text-title-middle lg:text-title-normal font-bold lg:absolute lg:top-40 lg:right-0 lg:text-6xl md:text-5xl text-4xl flex flex-col justify-center ">
            <div class="transform text-white -rotate-90 lg:block hidden">
              <div>ABOUT</div>
            </div>
            {/* <div class="lg:hidden block text-white text-center min-w-full flex flex-col justify-center">
              <div>ABOUT</div>
            </div> */}
            <div class="lg:hidden block text-white text-center min-w-full flex flex-col justify-center">
            {/* <div>Your brand tells<br></br> us a story, we<br></br> develop it for<br></br> the people.</div> */}
            </div>
          </div>
        </div>
      </div>

      <div class="margin-setts 2xl:pl-40 xl:pl-40 lg:pl-20 pl-10 2xl:pr-40 xl:pr-40 lg:pr-20 pr-10 bg-white-100 flex-col-re ">
        <div class="2xl:text-8xl xl:text-7xl lg:text-6xl md:text-5xl text-4xl lg:mt-60 mt-20 md:text-left text-center font-bold">
          Your brand tells us a story, we develop it for the people.
        </div>
        <div class=" lg:mt-60 mt-20 grid md:grid-cols-2 sm:grid-cols-1 md:gap-20 text-4xl">
          {members.map((member, index) => {
            const leftElem = (
              <div class="pt-20">
                <img src={member?.image?.asset?.url} />
              </div>
            );
            const [bio=null]=member?.bio||[];
            const bioText=bio?.children?.map(el=>el.text).join("\n");
            const rightElem = (
              <div class="pt-20">
                <div class="2xl:text-about-title-main xl:text-about-title-middle lg:text-about-title-normal text-2xl uppercase  font-bold whitespace-pre-wrap">{(member.role||"").split("<").join("\n")}</div>
                <div class="2xl:text-about-name-main xl:text-about-name-middle lg:text-about-name-normal text-2xl mt-5">
                  {member.firstName} {member.lastName}
                </div>
                <div class="2xl:text-about-description-main xl:text-about-description-middle lg:text-about-description-normal text-xl mt-5 whitespace-pre-wrap">
                 {bioText}
                </div>
              </div>
            );
            return [leftElem, rightElem];
          })}
        </div>
        <div class="2xl:text-caption-full-main xl:text-caption-full-middle lg:text-caption-full-normal text-2xl  lg:mt-60 mt-20 font-bold">
          We are an Italian Creative Studio whose practice is established
          between the flow of innovative ideas and the plans to implement them.
          <br></br>
          From strategies to content we take care of your Brand identity.
          Headquartered in Italy with a worldwide vision.
        </div>
        <div class="iframe smado lg:block hidden">
          <iframe class="iframe smado" src="https://player.vimeo.com/video/680480466?h=c52a01b670&autoplay=1&loop=1&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        <div class="iframe smado lg:hidden">
          <iframe class="iframe smado" src="https://player.vimeo.com/video/680486113?h=bc10d31771&autoplay=1&loop=1&title=0&byline=0&portrait=0"frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
      </div>

      <div class="bg-white-100 flex-col-re ">
        <div class="pl-40margin-setts 2xl:pr-40 xl:pr-40 lg:pr-20 pr-20 mt-20 flex-1 text-3xl font-bold"></div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default About;

export const pageQuery = graphql`
  {
    allSanityTeamMember {
      nodes {
        firstName
        lastName
        role
        bio {
          children {
           text
          }
        }
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;
